/**
 * @file 网站配置
 */
// import { requiredNumber } from 'element-plus/es/components/table-v2/src/common';
import { isClient } from '@vueuse/shared';
import siteConfig from './site';

// 网站支持的语言,seo生成的多语言html根据这个列表生成
export const langs: string[] = [
  'zh-CN',
  'en-US',
  // 'ko-KR',
  // 'ja-JP',
  // 'vi-VN',
  // 'es-ES',
  // 'zh-TW',
  // 'ru-RU',
  // 'tr-TR',
  'zh-TW'
  // 'ja-JP',
  // 'pt-PT'
  // 'pt-BR',
  // 'fr-FR',
  // 'th-TH',
  // 'id-ID',
];

// 网站默认语言(准备删除)
export const lang = 'zh-CN';

// 语言缓存key
export const STORE_LANG = 'lang';

export const STORE_LEGAL_KEY = 'legal_flag';

export const defaultLang = isClient ? (localStorage.getItem(STORE_LANG) || 'en-US') : 'en-US';

export const defaultLegalFlag = () => isClient ? (localStorage.getItem(STORE_LEGAL_KEY) || 'USD') : 'USD';

// 退出登录不跳登录页的页面
export const stayRoute = ['/', '/spot', '/h5/vip', '/vip', '/vipZone', '/activity/genesisVip', '/activity/anniversary', /^\/spot\/[a-zA-Z0-9]{1,20}_[a-zA-Z0-9]{1,20}$/, '/markets', '/futures', /^\/futures\/[a-zA-Z0-9\-]{1,20}$/, '/about-us', '/futures-activity', '/copytrading', '/download', '/card-deposit', '/invite', '/safeWay', /^\/safeWay\//, '/buy-cryptos', /^\/buy-cryptos\//, /\/activity\/api-customer-rewards/, '/hskPoints', '/hashBrokerage', /\/activity\/LunarNewYearTrading/, '/support-fee', '/h5/support-fee', '/limitedTimeWelcome', '/limitedPromotion', '/contactUs'];

// 谨慎！！不要修改，避免把测试环境地址带去线上
const env = import.meta.env.MODE;
const isHKFirstOrigin = env == 'stg' ? 'www' : 'hk';

// 接口域名(production放在最后面，防止被变量覆盖)
const apis:any = {
  [env]: `https://bapi-pro.${env}.hashkeydev.com`,
  production: 'https://bapi-pro.hashkey.com'
};
const wss:any = {
  [env]: `wss://bws-pro.${env}.hashkeydev.com`,
  production: 'wss://bws-pro.hashkey.com'
};
const apps:any = {
  [env]: `https://mapi-pro.${env}.hashkeydev.com`,
  production: 'https://mapi-pro.hashkey.com'
};
// 香港站host
const web:any = {
  [env]: `https://${isHKFirstOrigin}.${env}.hashkeydev.com`,
  production: 'https://www.hashkey.com'
};
// 国际站host
const webGB:any = {
  [env]: `https://global.${env}.hashkeydev.com`,
  production: 'https://global.hashkey.com'
};
// passport host
const webPP:any = {
  [env]: `https://passport.${env}.hashkeydev.com`,
  production: 'https://passport.hashkey.com'
};

export const apiHost = {
  api: apis[env],
  ws: wss[env],
  app: apps[env],
  web: web[env],
  webGB: webGB[env],
  webPP: webPP[env]
};

// 稳定币定义
export const stableToken = ['USDC', 'DAI'];

const apiLangMap: any = {
  'zh-CN': 'cn',
  'en-US': 'en'
};

export const getApiUrl = () => {
  const localeLang = defaultLang;
  const lang = localeLang && apiLangMap[localeLang] ? apiLangMap[localeLang] : 'en';

  return `https://Hashkeydocs.github.io/apidocs/spot/v1/${lang}/#45fa4e00db`;
};

// 获取默认语言
export const getDefaultLang = () => {
  const localStoreLang: string = localStorage.getItem(STORE_LANG) || lang;
  const langInfo = siteConfig.supportLanguagesMap[localStoreLang];

  return {
    langKey: localStoreLang,
    ...langInfo
  };
};

// 当前环境的location属性
export const envHost: any = isClient
  ? window.location.host
  : {
    [env]: `${isHKFirstOrigin}.${env}.hashkeydev.com`,
    production: 'www.hashkey.com'
  }[env];
export const envHref: string = isClient ? window.location.href : `https://${envHost}/`;
export const envOrigin: string = isClient ? window.location.origin : `https://${envHost}`;

export const downloadConfig: any = {
  webview: (path: any) => `hashkey://www.hashkey.com/webview?url=${envOrigin}${path}&needReload=true`,
  webviewToH5: (path: any) => `${envOrigin}${path}`,
  androidUrl: `${envOrigin}/download`,
  iOSUrl: `${envOrigin}/download`,
  duration: 3000
};